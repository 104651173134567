import React from "react"
import {StoryGroup} from "components";
import {flatStoryGroup} from "utils/array-util";

const LinkMap = {
	'AWS': '62c21805eb9a',
	'Scalability': '62c21805eb9a',
	'Vertical Scale': '62c21805eb9a',
	'Horizontal Scale': '62c21805eb9a',
	'Global Infrastructure': '62c21805eb9a',
	'Regions': '62c21805eb9a',
	'Availability Zones': '62c21805eb9a',
	'Edge Locations': '62c21805eb9a',
	'Multi-AZ': '62c21805eb9a',
	'RDS': '62c21805eb9a',
	'iOT Button': '62c21805eb9a',
	'Rekognition': '62c21805eb9a',
	'Polly': '62c21805eb9a',

	'Deployment,Administration,Security Services': '5267d4f8eb5c',
	'Application Services': '5267d4f8eb5c',
	'Data Services': '5267d4f8eb5c',
	'Network Services': '5267d4f8eb5c',
	'Compute Services': '5267d4f8eb5c',
	'Storage Services': '5267d4f8eb5c',
	'Infrastructure Services': '5267d4f8eb5c',

	'High Availability': '7c31d4fa729d',
	'High Scalability': '7c31d4fa729d',
	'REST API': '7c31d4fa729d',
	'Email Service': '7c31d4fa729d',
	'DB Service': '7c31d4fa729d',
	'Queue Service': '7c31d4fa729d',
	'InMem Cache Service': '7c31d4fa729d',
	'Bg Process Job': '7c31d4fa729d',
	'Push Notification': '7c31d4fa729d',

	'Frequently Used Services': 'b2a1c8264dd5',

	'Reinvent The Wheel': '3a3b69e84ce8',
	'Compute Layer': '3a3b69e84ce8',
	'Data Layer': '3a3b69e84ce8',
	'Messaging and Stream Layer': '3a3b69e84ce8',
	'Edge Layer': '3a3b69e84ce8',
	'System Monitoring & Dev': '3a3b69e84ce8',

	'Restfull Microservices Arch': '3a3b69e84ce8',
	'Alexa Skills Arch': '3a3b69e84ce8',
	'Mobile Backend Arch': '3a3b69e84ce8',
	'Stream Processing Arch': '3a3b69e84ce8',
	'Web Application Arch': '3a3b69e84ce8',

	'AWS Storage Services': '2367ff1f9dfa',
	'Storage-S3': '2367ff1f9dfa',
	'Storage-Glacier': '2367ff1f9dfa',
	'Storage-EBS': '2367ff1f9dfa',
	'Storage-EC2 Instance': '2367ff1f9dfa',
	'Storage-Storage Gateway': '2367ff1f9dfa',
	'Storage-Snowball': '2367ff1f9dfa',
	'Storage-Cloudfront': '2367ff1f9dfa',

	'AWS Lambda,APIGateway': '57f4b805da5e',

	'Cloudfront': '9e3922121d3b',

	'AnkaraCloudMeetup': 'c411c9f60b64',
	'AnkaraCloud-AWS': 'c411c9f60b64',
	'AnkaraCloud-Fault Tolerant': 'c411c9f60b64',
	'AnkaraCloud-Highly Available': 'c411c9f60b64',
	'AnkaraCloud-Scalable Arch': 'c411c9f60b64',
	'AnkaraCloud-BigData': 'c411c9f60b64',
	'AnkaraCloud-Serverless': 'c411c9f60b64',
	'AnkaraCloud-DevOps Culture': 'c411c9f60b64',
	'AnkaraCloud-Scaling Realtime': 'c411c9f60b64',
	'AnkaraCloud-Reliable Software': 'c411c9f60b64',
	'AnkaraCloud-Izlesene': 'c411c9f60b64',
	'AnkaraCloud-Data Replication': 'c411c9f60b64',
	'AnkaraCloud-Thinking Distributed': 'c411c9f60b64',
	'AnkaraCloud-Docker': 'c411c9f60b64',
	'AnkaraCloud-Kafka': 'c411c9f60b64',
	'AnkaraCloud-Kubernetes': 'c411c9f60b64',
	'AnkaraCloud-WebRTC': 'c411c9f60b64',
	'AnkaraCloud-SlackAppDev': 'c411c9f60b64',

	"AWS Certification MindMap": "77cab29df8bb",
	"AWS Certification Hints": "23f99d927ae1",
	"AWS Certificate Hints2": "77823d53e82f",
	"Architecting for the Cloud": "ecc156c48a7b",

	"S3": "f6b68c318273",
	"EC2": "6d50cb906b38",
	"Lambda": "a1583af0f8f6",
	"ECS": "972275e2d446",
	"SNS": "2e4328901e78",
	"Lambda-CI/CD": "5673905e8ab2",
	"Apache-James": "48955e3751d3",
	"Route53-Naked Domain": "2764455e2929",
	"Route53-Redirection Policies": "9a969bb9c837",
	"Route53-Move Domain": "53c6f970afe7",
	"MySQL Turkish Encoding": "f3f5cd41441d",


	"IaaS,PaaS,FaaS": "3bb5620fd357",
	"IoT": "9164dbfbb8ef",
	"SaaS": "7761cc17bd88",
	"Lambda Algorithm": "da5cfd569086",


}


const storiesGroup = [
	{
		title: "Genel",
		storyPart: [
			{
				title: "Servisleri",
				postId: "62c21805eb9a",
			},
			{
				title: "Platformu ve Servisleri",
				postId: "5267d4f8eb5c",
			},
			{
				title: "Servislerini Neden Kullanmalıyım?",
				postId: "7c31d4fa729d",
			},
			{
				title: "Sık Kullanılan Servisler",
				postId: "b2a1c8264dd5",
			},
			{
				title: "Application Servislerin Avantajı",
				postId: "3a3b69e84ce8",
			},
			{
				title: "Storage",
				postId: "2367ff1f9dfa",
			}, {
				title: "Lambda, API Gateway ile WebApp REST API",
				postId: "57f4b805da5e",
			},
			{
				title: "CloudFront",
				postId: "9e3922121d3b",
			},
		]
	}, {
		title: 'Etkinlikler',
		storyPart: [
			{
				title: "Ankara Cloud Meetup",
				postId: "c411c9f60b64",
			},
		]
	}, {
		title: 'Sertifikalar',
		storyPart: [
			{
				title: "Developer Associate Sertifikasi için MindMap Kullanma",
				postId: "77cab29df8bb",

			}, {
				title: "Developer Associate Sınavı için İpuçları",
				postId: "23f99d927ae1",
			}, {
				title: "AWS Sertifika Sınavlarına Hazırlık İçin İpuçları",
				postId: "77823d53e82f",
			}, {
				title: "Architecting for the Cloud: AWS Best Practices Notes",
				postId: "ecc156c48a7b",
			},
		]
	}, {
		title: 'Teknik',
		storyPart: [
			{
				title: "S3 Servisini Nasıl Kullanabilirim?",
				postId: "f6b68c318273",
			}, {
				title: "EC2 üzerinde Sunucu Yönetimi",
				postId: "6d50cb906b38",
			}, {

				title: "Lambda",
				postId: "a1583af0f8f6",
			}, {
				title: "ECS (Elastic Container Service)",
				postId: "972275e2d446",
			}, {
				title: "SNS Notification için Kullanma",
				postId: "2e4328901e78",
			}, {
				title: "Lambda için Continuous Deployment",
				postId: "5673905e8ab2",
			}, {
				title: "ePosta Alıp İşlemek için Apache James/SES(Simple Email Service)",
				postId: "48955e3751d3",
			}, {
				title: "AWS Route53'de Naked Domain Tanımlama",
				postId: "2764455e2929",
			}, {
				title: "AWS Route 53 Yönlendirme Politikaları",
				postId: "9a969bb9c837",
			}, {
				title: "Domain İsmini AWS Route53 Taşıma",
				postId: "53c6f970afe7",
			}, {
				title: "RDS MySQL Veritabanına Türkçe Karakter Desteği",
				postId: "f3f5cd41441d",
			},
		]
	}, {
		title: 'Kavramlar',
		storyPart: [
			{
				title: "Bulut Hizmetlerinde IaaS, PaaS, SaaS nedir?",
				postId: "3bb5620fd357",
			}, {
				title: "iOT (Nesnelerin İnterneti) Nedir ?",
				postId: "9164dbfbb8ef",
			}, {
				title: "SaaS (Software As A Service) Ortak Özellikleri",
				postId: "7761cc17bd88",
			}, {
				title: "AWS Lambda ve Algorithmia",
				postId: "da5cfd569086",

			}
		]
	}
]

export const context = {
	stories: flatStoryGroup(storiesGroup),
	linkMap: LinkMap,
	title: 'AWS(Amazon Web Services)',
	path: 'aws'
}


const AWSPage = () => (
	<StoryGroup
		title={context.title}
		complex={true}
		stories={storiesGroup}
		linkMap={context.linkMap}
	/>

)

export default AWSPage


